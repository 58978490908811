import { useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ISO_CODE_TO_COUNTRY_NAME } from "core/constants";
import ChevronComponent from "tpo/Chevron";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import StripeCalloutBanner from "./StripeCalloutBanner";

export default function YourLinkedBankAccount() {
  const { organisation } = useOrganisationContext();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const address =
    (!organisation.stripeConnectedAccountAddress && {}) ||
    JSON.parse(organisation.stripeConnectedAccountAddress);

  return (
    <>
      <Stack gap={20}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Your linked bank account
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          You have successfully verified your business account and linked your payment details. If
          you need to update any details please edit below
        </Box>
      </Stack>
      <Stack gap={[40]}>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Business address
        </Box>
        <Stack gap={20}>
          {!!organisation?.stripeConnectedAccountName && (
            <FloatingLabelInput
              label="Business name"
              value={organisation.stripeConnectedAccountName}
              editable={false}
            />
          )}
          {!!organisation?.line1 && (
            <FloatingLabelInput
              label="Address line 1"
              value={address?.line1 || ""}
              editable={false}
            />
          )}
          {!!organisation?.line2 && (
            <FloatingLabelInput
              label="Address line 2"
              value={address?.line2 || ""}
              editable={false}
            />
          )}
          {!!organisation?.city && (
            <FloatingLabelInput label="City" value={address?.city || ""} editable={false} />
          )}
          {!!organisation?.postcode && (
            <FloatingLabelInput
              label="Postcode"
              value={address?.postal_code || ""}
              editable={false}
            />
          )}
          {!!organisation?.country?.name && (
            <FloatingLabelInput
              label="Country"
              value={address?.country ? ISO_CODE_TO_COUNTRY_NAME[address.country] : ""}
              editable={false}
            />
          )}
        </Stack>
      </Stack>
      <Stack gap={[40]}>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Business details
        </Box>
        <Stack gap={20}>
          {!!organisation?.website && (
            <FloatingLabelInput label="Website" value={organisation.website} editable={false} />
          )}
          {!!organisation?.vatNumber && (
            <FloatingLabelInput
              label="VAT number"
              value={organisation.vatNumber}
              editable={false}
            />
          )}
          {!!organisation?.companyNumber && (
            <FloatingLabelInput
              label="Company number"
              value={organisation.companyNumber}
              editable={false}
            />
          )}
          {!!organisation?.contactNumber && (
            <FloatingLabelInput
              label="Contact number"
              value={organisation.contactNumber}
              editable={false}
            />
          )}
        </Stack>
      </Stack>
      <Stack gap={40}>
        <StripeCalloutBanner fontSize={14} />
        <ButtonV2
          cursor="pointer"
          as={ExternalTextLink}
          underline={false}
          target="_self"
          rel="noopener noreferrer"
          mx="auto"
          color="green"
          rightIcon={<ChevronComponent />}
          onClick={() => setButtonDisabled(true)}
          disabled={buttonDisabled}
          type="button"
          size={["sm", "sm", "md"]}
          href={organisation?.onboardingUrl}
        >
          edit
        </ButtonV2>
      </Stack>
    </>
  );
}
