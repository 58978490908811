import React from "react";
import { useEffect } from "react";

import ReactDOM from "react-dom";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/browser";
import "array-flat-polyfill";

import { isTouch } from "./core/utils";
import "./styles/custom-properties.scss";
import "./styles/resets.scss";
import App from "./tpo/App";

const release = process.env.REACT_APP_VERSION || "0.0.0";

console.log(`Omnos : v${release}`);
console.log(`REACT_APP_ENV is ${process.env.REACT_APP_ENV || "local"}`);
console.log(`NODE_ENV is ${process.env.NODE_ENV || "local"}`);

if (process.env.NODE_ENV !== "development") {
  // TODO do we get anything extra from doing this?
  // https://medium.com/@vshab/create-react-app-and-sentry-cde1f15cbaa
  if (process.env.REACT_APP_SENTRY_DSN) {
    console.log(`Setting up Sentry with DSN ${process.env.REACT_APP_SENTRY_DSN}`);
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: release,
      environment: process.env.REACT_APP_ENV,
      ignoreErrors: [
        // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/23
        "Object Not Found Matching Id",
        "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')"
      ],
      ignoreUrls: [
        "https://js.hscollectedforms.net/collectedforms.js",
        "https://www.googletagmanager.com/gtag/js?id=G-SXBCE6MPG9"
      ]
    });
  }
}

if (process.env.REACT_APP_ENV === "production") {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  window.gtag("config", "UA-156447102-1");
}

if (isTouch()) {
  document.body.setAttribute("data-touch", true);
}

function AppWrapper() {
  const location = useLocation();

  useEffect(() => {
    const isPartnerOrPatientPath = path => /^\/(partners|patients)/.test(path);
    const currentPath = location.pathname;

    if (window.initialPath === undefined) {
      window.initialPath = currentPath;
    } else if (isPartnerOrPatientPath(currentPath) !== isPartnerOrPatientPath(window.initialPath)) {
      window.location.reload();
    }
  }, [location]);

  return <App />;
}

ReactDOM.render(
  <Router>
    <AppWrapper />
  </Router>,
  document.getElementById("root")
);
