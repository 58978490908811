import { useEffect, useState } from "react";

import { useParams, Redirect } from "react-router-dom";

import { gql, useMutation, useQuery } from "@apollo/client";
import Box from "components/Box";
import Image from "components/Image";
import { DownloadCrossOriginFile } from "components/Links";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { theme } from "core/theme";
import { COURSE_FIELDS, COURSE_QUERY, COURSE_VIDEO_FIELDS } from "graphql/knowledgeCenter/webinars";
import padlockImage from "images/tpo/padlock.svg";
import { uniqBy } from "lodash";
import Accordion, { useAccordionControl } from "tpo/Accordion";
import Badge from "tpo/Badge";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import convertOrderedLists from "utils/convertOrderedLists";
import copyTextToClipboard from "utils/copyTextToClipboard";
import ButtonV2, { ActionIcon } from "v2/Buttons";

import courseClasses from "./Course.module.css";
import FeaturedTests from "./FeaturedTests";
import VimeoComponent from "./VimeoComponent";
import classes from "./richText.module.css";

const VIDEO_STARTED = "VIDEO_STARTED";
const VIDEO_ENDED = "VIDEO_ENDED";

export const RECORD_VIDEO_EVENT_MUTATION = gql`
  mutation RecordVideoEventMutation($input: RecordVideoEventMutationInput!) {
    recordVideoEventMutation(input: $input) {
      courseVideo {
        ...CourseVideoFields
      }
    }
  }
  ${COURSE_VIDEO_FIELDS}
`;

export const SIGN_UP_FOR_EXCLUSIVE_ACCESS_MUTATION = gql`
  mutation SignUpForExclusiveAccessMutation(
    $input: SignUpForExclusiveAccessMutationInput!
    $paymentIsoCode: String
    $organisation: ID
  ) {
    signUpForExclusiveAccessMutation(input: $input) {
      course {
        ...CourseFields
      }
    }
  }
  ${COURSE_FIELDS}
`;

function ExclusiveDiscountOffer({ discountCode, offerText, offerCaveat }) {
  return (
    <Box px={20} bg="purple" color="white" borderRadius={20} py={60}>
      <Box color="inherit" fontFamily="gilroyBold" fontSize={[24, 24, 32]} textAlign="center">
        Course Offer
      </Box>
      {offerText && (
        <Box
          color="inherit"
          fontFamily="gilroyMedium"
          fontSize={[14, 14, 16]}
          textAlign="center"
          mt={2}
        >
          {offerText}
        </Box>
      )}
      <Group
        flexWrap="wrap"
        color="inherit"
        gap={[20, 20, 40]}
        justifyContent="center"
        mt={40}
        alignItems="center"
      >
        <Box color="inherit">
          <Box color="inherit" fontSize={12} textTransform="uppercase">
            Discount code:
          </Box>
          <Box color="inherit" fontFamily="gilroyBold" fontSize={32}>
            {discountCode}
          </Box>
        </Box>
        <ButtonV2
          color="green"
          size="sm"
          onClick={() => {
            copyTextToClipboard(discountCode);
          }}
        >
          copy code
        </ButtonV2>
      </Group>
    </Box>
  );
}

function CourseVideo({ py, video, title, isClosed, setPlayVideo, setVideoBeingWatched }) {
  return (
    <Group
      py={py}
      className={courseClasses.videoSummary}
      flexWrap="wrap"
      alignItems="center"
      gap={20}
    >
      <Group alignItems="center" gap={20}>
        <img
          src={video.thumbnailUrl}
          width={100}
          height={65}
          alt="thumbnail"
          style={{
            backgroundColor: theme.colors.partners,
            color: "transparent",
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        />
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          {title}
        </Box>
      </Group>
      <Group alignItems="center" gap={16} ml="auto">
        {video.userEnded && (
          <Badge fontSize={9} py="8px" px={16} bg="green" color="white">
            watched
          </Badge>
        )}
        {video.userStarted && !video.userEnded && (
          <Badge fontSize={9} py="8px" px={16} bg="dark" color="white">
            started
          </Badge>
        )}
        {isClosed ? (
          <Image src={padlockImage} height={40} width={40} />
        ) : (
          <ActionIcon
            bg="dark"
            variant="circle"
            size={["sm", "sm", "md"]}
            onClick={() => {
              setVideoBeingWatched(video);
              setPlayVideo(true);
            }}
          >
            <ChevronComponent
              style={{
                marginLeft: 0
              }}
            />
          </ActionIcon>
        )}
      </Group>
    </Group>
  );
}

CourseVideo.defaultProps = {
  py: 28
};

function ProgressBar({ value, maxWidth }) {
  return (
    <Group alignItems="center" flexWrap="wrap" gap={20} mt={28} maxWidth={maxWidth} width="100%">
      <Box fontFamily="gilroyBold" fontSize={12} textTransform="uppercase" letterSpacing={2.8}>
        progress
      </Box>
      <Box flexGrow={1} height={8} borderRadius={100} bg="#E5E5E5">
        <Box bg="purple" width={`${value}%`} height="100%" borderRadius={100} />
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        {`${value}%`}
      </Box>
    </Group>
  );
}

function CourseVideoAccordionControl({ progress, title }) {
  const { onClick, cursor, className, chevronIcon, size, color } = useAccordionControl({
    expandable: true,
    iconSize: [40, 40, 48]
  });

  return (
    <Box color={color} className={className} cursor={cursor} onClick={onClick}>
      <Group justifyContent="space-between">
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
          {title}
        </Box>
        {chevronIcon}
      </Group>
      {progress !== undefined && <ProgressBar value={progress * 100} />}
    </Box>
  );
}

function getUniqueCourseVideos(course) {
  return uniqBy(
    [
      course.introductionVideo,
      ...course.modules.map(m => m.videos.map(v => v.video).flat()).flat()
    ].filter(v => v),
    "id"
  );
}

function CourseVideos({ closed = false, course, setVideoBeingWatched, setPlayVideo }) {
  const uniqueCourseVideos = getUniqueCourseVideos(course);

  const overallProgress = uniqueCourseVideos.length
    ? uniqueCourseVideos?.filter(v => v.userEnded)?.length / uniqueCourseVideos?.length
    : undefined;

  const header = closed ? (
    <Center stacked gap={0}>
      <Image src={padlockImage} height={70} width={70} />
      <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]} textAlign="center" mt={20}>
        Early access closed
      </Box>
      <Box fontFamily="gilroyMedium" fontSize={16} textAlign="center" mt={28}>
        This course is currently closed, it will be available on general release from{" "}
        {new Date(course?.exclusiveAccessEndTime).toLocaleDateString()}
      </Box>
    </Center>
  ) : (
    <Center stacked>
      <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]} textAlign="center">
        Course videos
      </Box>
      {overallProgress !== undefined && (
        <ProgressBar maxWidth={720} value={overallProgress * 100} />
      )}
    </Center>
  );

  return (
    <Stack stacked gap={40} pt={80} pb={120}>
      {header}
      <Center alignItems="unset" gap={40} flexWrap="wrap">
        <Stack width="100%" maxWidth={[null, null, 720]}>
          {course?.introductionVideo && (
            <Stack
              bg="white"
              borderRadius={20}
              px={40}
              py={40}
              width="100%"
              maxWidth={720}
              gap={40}
              mb="auto"
            >
              <CourseVideo
                py={0}
                isClosed={false} // always allow the top video, assumed to be the introduction video, be playable
                title="Introduction"
                video={course?.introductionVideo}
                setPlayVideo={setPlayVideo}
                setVideoBeingWatched={setVideoBeingWatched}
              />
            </Stack>
          )}
          <Accordion
            variant="separated"
            chevronVariant="circle"
            multiple
            size={20}
            gap={[10, 10, 20]}
          >
            {course?.modules?.map(module => {
              const progress = module.videos.length
                ? module.videos?.filter(v => v.video.userEnded)?.length / module.videos?.length
                : undefined;

              return (
                <Accordion.Item bg="white" p={[40]} borderRadius={20} value={module.id}>
                  <CourseVideoAccordionControl progress={progress} title={module.title} />
                  <Accordion.Panel position="relative" p={0}>
                    <Stack key={module.id} gap={0}>
                      {module.videos.map(video => (
                        <CourseVideo
                          key={video.id}
                          isClosed={closed}
                          title={video.title}
                          video={video.video}
                          setPlayVideo={setPlayVideo}
                          setVideoBeingWatched={setVideoBeingWatched}
                        />
                      ))}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Stack>
        <Stack
          bg="white"
          borderRadius={20}
          px={[24, 24, 40]}
          py={40}
          width="100%"
          maxWidth={[null, null, 520]}
          gap={20}
          mb="auto"
        >
          <Box fontFamily="gilroyBold" fontSize={[28]}>
            Related documents
          </Box>
          {course.documents.map(document => (
            <Group key={document.id} alignItems="center" gap={20} justifyContent="space-between">
              <Box fontFamily="gilroyMedium" fontSize={16}>
                {document.filename}
              </Box>
              {closed ? (
                <Image src={padlockImage} height={30} width={30} />
              ) : (
                <DownloadCrossOriginFile
                  fileUrl={document.url}
                  fileName={document.filename}
                  contentType={document.contentType}
                  trigger={
                    <ButtonV2
                      color="green"
                      rightIcon={<ChevronComponent direction="bottom" />}
                      size="xs"
                    >
                      Download
                    </ButtonV2>
                  }
                  containerProps={{
                    ml: "auto"
                  }}
                />
              )}
            </Group>
          ))}
        </Stack>
      </Center>
    </Stack>
  );
}

export default function Course() {
  const { slug } = useParams();
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  const skipQuery = !basket?.paymentLocale?.isoCode || !organisation?.id;

  const { data: courseData, loading: loadingcourse } = useQuery(COURSE_QUERY, {
    variables: {
      slug,
      paymentIsoCode: basket?.paymentLocale?.isoCode,
      organisation: organisation?.id
    },
    skip: skipQuery
  });

  const [recordVideoEvent] = useMutation(RECORD_VIDEO_EVENT_MUTATION);
  const [signUpForExclusiveAccessMutation] = useMutation(SIGN_UP_FOR_EXCLUSIVE_ACCESS_MUTATION);

  const course = courseData?.course;

  const [videoBeingWatched, setVideoBeingWatched] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    if (course && videoBeingWatched === null) {
      const courseVideos = getUniqueCourseVideos(course);
      if (courseVideos.length) {
        setVideoBeingWatched(courseVideos[0]);
      }
    }
  }, [course, videoBeingWatched]);

  const onVideoStarted = () => {
    if (videoBeingWatched.userStarted) return;
    recordVideoEvent({
      variables: {
        input: {
          event: VIDEO_STARTED,
          video: videoBeingWatched.id
        }
      }
    });
  };
  const onVideoEnded = () => {
    if (videoBeingWatched.userEnded) return;
    recordVideoEvent({
      variables: {
        input: {
          event: VIDEO_ENDED,
          video: videoBeingWatched.id
        }
      }
    });
  };

  const signUpForExclusiveAccess = () => {
    signUpForExclusiveAccessMutation({
      variables: {
        input: {
          id: course.id
        },
        organisation: organisation?.id,
        paymentIsoCode: basket?.paymentLocale?.isoCode
      }
    });
  };

  if (!skipQuery && !loadingcourse && !course) return <Redirect to="/404" />;

  let contentBeneathVideo = null;

  if (new Date(course?.exclusiveAccessStartTime) > new Date()) {
    if (!course?.hasExclusiveAccess) {
      contentBeneathVideo = (
        <Center pt={80} pb={120} gap={20} alignItems="unset" flexWrap="wrap">
          <Stack bg="white" gap={40} p={40} pb={60} maxWidth={640} borderRadius={20}>
            <Stack gap={28}>
              <Box
                as="h2"
                fontFamily="gilroyBold"
                fontSize={[32, 32, 44]}
                textAlign="center"
                lineHeight={["36px", "36px", "50px"]}
              >
                Apply for exclusive early access
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} textAlign="center">
                Sign up before {new Date(course?.exclusiveAccessStartTime).toLocaleDateString()} to
                get exclusive access and receive your discount when the course starts
              </Box>
            </Stack>
            <ButtonV2
              type="button"
              color="green"
              size={["sm", "sm", "md"]}
              rightIcon={<ChevronComponent />}
              mx="auto"
              onClick={signUpForExclusiveAccess}
            >
              sign up now
            </ButtonV2>
          </Stack>
          {course?.discountCode && (
            <Stack
              bg="purple"
              p={40}
              pb={20}
              borderRadius={20}
              color="white"
              flexGrow={1}
              maxWidth={640}
            >
              <Box
                fontFamily="gilroyBold"
                fontSize={[32, 32, 44]}
                textAlign="center"
                color="inherit"
                textTransform="uppercase"
              >
                Course Offer
              </Box>
              <Box
                fontSize={[14, 14, 16]}
                fontFamily="gilroyMedium"
                textAlign="center"
                color="inherit"
              >
                {course?.offerText}
              </Box>
              <Box
                fontFamily="gilroyMedium"
                fontSize={12}
                mt="auto"
                textAlign="center"
                color="inherit"
              >
                {course?.offerCaveat}
              </Box>
            </Stack>
          )}
        </Center>
      );
    } else {
      contentBeneathVideo = (
        <Center pt={80} pb={120}>
          <Stack bg="white" gap={28} p={40} pb={60} maxWidth={720} borderRadius={20}>
            <Box fontFamily="gilroyBold" fontSize={[32, 32, 44]} textAlign="center">
              Course begins {new Date(course?.exclusiveAccessStartTime).toLocaleDateString()}
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} textAlign="center">
              Thanks for signing up to exclusive access. We will be in touch to remind you when the
              course starts.
            </Box>
          </Stack>
        </Center>
      );
    }
  } else if (course?.hasExclusiveAccess || new Date() > new Date(course?.exclusiveAccessEndTime)) {
    contentBeneathVideo = (
      <CourseVideos
        course={course}
        setVideoBeingWatched={setVideoBeingWatched}
        setPlayVideo={setPlayVideo}
      />
    );
  } else if (course) {
    contentBeneathVideo = (
      <CourseVideos
        course={course}
        setVideoBeingWatched={setVideoBeingWatched}
        setPlayVideo={setPlayVideo}
        closed
      />
    );
  }

  const courseVideos = [
    course?.introductionVideo,
    ...(course?.modules?.map(m => m.videos).flat() || [])
  ].filter(v => v);

  return (
    <>
      <Box px={20} pt={theme.spacing.pageHeader.pt}>
        <Center stacked gap={28} pt={[60, 60, 80]} pb={60}>
          <Box
            textAlign="center"
            fontFamily="gilroyBold"
            fontSize={[40, 40, 68]}
            lineHeight="100%"
            maxWidth={1024}
          >
            {course?.title}
          </Box>
          <Box textAlign="center" fontFamily="gilroyRegular" fontSize={[24, 24, 32]}>
            {course?.subtitle}
          </Box>
        </Center>
      </Box>
      {!!courseVideos?.length && (
        <Box bg="haze" pt={60} px={20}>
          {!!videoBeingWatched && (
            <VimeoComponent
              height={720}
              url={videoBeingWatched.url}
              width={1274}
              play={playVideo}
              key={videoBeingWatched.url}
              onStart={onVideoStarted}
              onEnd={onVideoEnded}
            />
          )}
          {contentBeneathVideo}
        </Box>
      )}
      <Box
        maxWidth={720}
        width="100%"
        mx="auto"
        px={20}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
      >
        {!!course?.description && (
          <Stack>
            <Box fontFamily="gilroyBold" fontSize={[40, 40, 68]} textAlign="center">
              Course details
            </Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: course?.description
                  ? convertOrderedLists(course.description)
                  : course?.description
              }}
              className={classes.richText}
            />
          </Stack>
        )}
        {!!course?.testProducts?.length && (
          <FeaturedTests testProducts={course.testProducts}>
            {course?.hasExclusiveAccess &&
            new Date() >= new Date(course?.exclusiveAccessStartTime) &&
            new Date() <= new Date(course?.exclusiveAccessEndTime) ? (
              <ExclusiveDiscountOffer
                offerCaveat={course?.offerCaveat}
                offerText={course?.offerText}
                discountCode={course?.discountCode?.code}
              />
            ) : null}
          </FeaturedTests>
        )}
      </Box>
    </>
  );
}
