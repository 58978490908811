import gql from "graphql-tag";
import { PAGE_INFO_FIELDS } from "graphql/pagination";
import { ORDER_TEST_ITEM_FIELDS } from "graphql/shop";
import { USER_TEST_FIELDS_TRUNCATED } from "graphql/tpo/results/types";

import { CONNECTED_USER_FRAGMENT } from "../shared";

export const CONNECTED_USERS_QUERY = gql`
  query ConnectedUsersQuery($page: Int, $orderBy: String, $search: String, $organisationId: ID!) {
    connectedUsersPagination(
      page: $page
      orderBy: $orderBy
      search: $search
      organisationId: $organisationId
    ) {
      items {
        ...ConnectedUserFragment
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${CONNECTED_USER_FRAGMENT}
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_USER_TESTS_FOR_REVIEW_QUERY = gql`
  query OrganisationUserTestsForReview($organisation: ID!) {
    organisationUserTestsForReview(organisation: $organisation) {
      id
      user {
        id
        fullName
        email
      }
      testProduct {
        id
        name
        paymentIsoCode
        organisation
      }
      orderTestItem {
        id
        providerTestId
      }
    }
  }
`;

export const PATIENT_TESTS = gql`
  query PatientTests(
    $userId: ID!
    $userTestId: ID!
    $allTests: Boolean!
    $includeUnapproved: Boolean!
  ) {
    ordersInProgress(userId: $userId) {
      ...OrderTestItemFields
    }
    userTests(userId: $userId, allTests: $allTests, includeUnapproved: $includeUnapproved) {
      ...UserTestFieldsTruncated
    }
    userTest(userId: $userId, userTestId: $userTestId) {
      ...UserTestFieldsTruncated
      orderTestItem {
        id
        order {
          id
          status
          sourceBasket {
            payee
          }
          cmpOrganisation {
            id
            accountOnHold
          }
        }
      }
    }
    user {
      id
      partnerUserProfile {
        id
        organisationSet {
          id
        }
      }
    }
  }
  ${ORDER_TEST_ITEM_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
`;
