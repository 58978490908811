import { forwardRef } from "react";

import Box from "components/Box";
import Circle from "components/Circle";
import { DownloadCrossOriginFile } from "components/Links";
import { theme } from "core/theme";
import PageIcon from "images/PageIcon";
import Badge from "tpo/Badge";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const SupportDocumentCard = forwardRef(
  ({ displayName, contentType, filename, description, url, type, testProductNames }, ref) => {
    return (
      <Stack bg="white" p={40} borderRadius={20} ref={ref} gap={20}>
        <Stack gap={8}>
          <Circle bg="transparent" size={40} border="1px solid black">
            <PageIcon fill={theme.colors.dark} size={20} />
          </Circle>
          <Box fontFamily="gilroyBold" fontSize={18}>
            {displayName}
          </Box>
          {description && (
            <Box fontFamily="gilroyMedium" fontSize={16}>
              {description}
            </Box>
          )}
        </Stack>
        <Group alignItems="center">
          <Group gap="2px" flexWrap="wrap">
            {type && (
              <Badge bg="purple" color="white" size="xs">
                {type}
              </Badge>
            )}
            {testProductNames?.map(name => (
              <Badge key={name} bg="haze" color="dark" size="xs">
                {name}
              </Badge>
            ))}
          </Group>
          <DownloadCrossOriginFile
            fileUrl={url}
            fileName={filename}
            contentType={contentType}
            trigger={
              <ButtonV2 color="dark" rightIcon={<ChevronComponent direction="bottom" />} size="md">
                Download
              </ButtonV2>
            }
            containerProps={{
              ml: "auto"
            }}
          />
        </Group>
      </Stack>
    );
  }
);

export default SupportDocumentCard;
