import { theme } from "core/theme";
import useDocTitle from "hooks/use-doc-title";
import { PanelBoxV2 } from "tpo/Boxes";
import Spacer from "tpo/Spacer";

import OrganisationTabs from "../OrganisationTabs";
import OrganisationAccountBalance from "./ui/OrganisationAccountBalance";
import OrganisationListCommissions from "./ui/OrganisationListCommissions";

export default function OrganisationCommissions() {
  useDocTitle("Commissions", false, "Regenerus");
  return (
    <>
      <OrganisationTabs selectedTab="commissions" />
      <PanelBoxV2
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20],
          bg: "haze"
        }}
      >
        <Spacer py={[2, 2, 20]} />
        <OrganisationListCommissions />
        <Spacer py={[2, 2, 20]} />
        {/*
        <OrganisationListPayouts />
        <Spacer py={[2, 2, 20]} />
        */}
        <OrganisationAccountBalance />
      </PanelBoxV2>
    </>
  );
}
