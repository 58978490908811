import { FormProvider } from "react-hook-form";

import Box from "components/Box";
import Divider from "components/Divider";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import useShippingAddressForm from "hooks/useShippingAddressForm";
import useShippingCountries from "hooks/useShippingCountries";
import Center from "tpo/Center";
import { Error } from "tpo/InputWrapper";
import Stack from "tpo/Stack";

import { ShippingAddressFields } from "../checkout/forms/ShippingAddressCheckoutForm";
import PatientNotificationSettingsForm from "./forms/PatientNotificationSettingsForm";
import UpdateContactEmailForm from "./forms/UpdateContactEmailForm";
import BusinessTemplate from "./ui/BusinessTemplate";

function PrimaryShippingAddress({ shippingCountries }) {
  const { organisation } = useOrganisationContext();
  const shippingAddress = organisation?.primaryAddress;

  const { methods, submitButtonRef, onSubmit, nonFieldError, isDirty } = useShippingAddressForm({
    shippingAddress,
    extraValues: {
      primaryAddress: true
    }
  });

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <Stack
        as="form"
        gap={[20, 20, 40]}
        onSubmit={methods.handleSubmit(onSubmit)}
        data-component-name="PrimaryAddressForm"
      >
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
          Shipping address 1
        </Box>
        <ShippingAddressFields shippingCountries={shippingCountries} showButtons={isDirty} />
      </Stack>
      {nonFieldError && (
        <Center>
          <Error error={nonFieldError} />
        </Center>
      )}
    </FormProvider>
  );
}

function SecondaryShippingAddress({ shippingCountries }) {
  const { organisation } = useOrganisationContext();
  const shippingAddress = organisation?.secondaryAddress;

  const { methods, submitButtonRef, onSubmit, nonFieldError, isDirty } = useShippingAddressForm({
    shippingAddress,
    extraValues: {
      primaryAddress: false
    }
  });

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <Stack
        as="form"
        gap={[20, 20, 40]}
        onSubmit={methods.handleSubmit(onSubmit)}
        data-component-name="SecondaryAddressForm"
      >
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
          Shipping address 2
        </Box>
        <ShippingAddressFields shippingCountries={shippingCountries} showButtons={isDirty} />
      </Stack>
      {nonFieldError && (
        <Center>
          <Error error={nonFieldError} />
        </Center>
      )}
    </FormProvider>
  );
}

export default function Settings() {
  const shippingCountries = useShippingCountries();

  return (
    <BusinessTemplate active="settings">
      <PartnerDashboardBox>
        <PatientNotificationSettingsForm />
      </PartnerDashboardBox>
      <PartnerDashboardBox>
        <UpdateContactEmailForm />
      </PartnerDashboardBox>
      <PartnerDashboardBox>
        <Stack gap={[24, 24, 28]}>
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Shipping address's
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Save time at check out with multiple shipping addresses for your organisation.
          </Box>
        </Stack>
        <PrimaryShippingAddress shippingCountries={shippingCountries} />
        <Divider color="dark" />
        <SecondaryShippingAddress shippingCountries={shippingCountries} />
      </PartnerDashboardBox>
    </BusinessTemplate>
  );
}
