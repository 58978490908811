import { useEffect } from "react";

import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Center from "tpo/Center";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const UPDATE_CONTACT_EMAIL_MUTATION = gql`
  mutation UpdateContactEmailMutation($input: UpdateContactEmailMutationInput!) {
    updateContactEmailMutation(input: $input) {
      organisation {
        id
        adminOverrideEmail
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default function UpdateContactEmailForm() {
  const { organisation } = useOrganisationContext();

  const api = useDjangoGraphqlForm({
    mutation: UPDATE_CONTACT_EMAIL_MUTATION,
    mutationName: "updateContactEmailMutation",
    defaultValues: {
      adminOverrideEmail: organisation?.adminOverrideEmail || ""
    },
    transformer: data => ({
      ...data,
      id: organisation?.id
    })
  });

  const { reset } = api.methods;

  useEffect(() => {
    if (organisation?.adminOverrideEmail !== undefined) {
      reset({
        adminOverrideEmail: organisation?.adminOverrideEmail || ""
      });
    }
  }, [organisation?.adminOverrideEmail, reset]);

  const { isDirty } = api.methods.formState;

  return (
    <FormProvider {...api.methods}>
      <Stack
        as="form"
        gap={40}
        onSubmit={api.methods.handleSubmit(api.onSubmit)}
        mx="auto"
        maxWidth={720}
        width="100%"
        px={20}
      >
        <Stack gap={20}>
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Organisation notification
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Add your preferred email for notifications below.
          </Box>
        </Stack>
        <Stack gap={20}>
          <ControlledFormField
            name="adminOverrideEmail"
            Component={FloatingLabelInput}
            label="Email address"
          />
        </Stack>
        {isDirty && (
          <Center gap={20}>
            <ButtonV2
              type="button"
              size={["sm", "sm", "md"]}
              color="red"
              onClick={() => {
                api.methods.reset();
              }}
            >
              Cancel
            </ButtonV2>
            <ButtonV2 size={["sm", "sm", "md"]} color="green">
              Save
            </ButtonV2>
          </Center>
        )}
      </Stack>
    </FormProvider>
  );
}
