import { useContext } from "react";

import { gql } from "@apollo/client";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { theme } from "core/theme";
import useDocTitle from "hooks/use-doc-title";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import DateInput from "tpo/DateInput";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import InputWrapper from "tpo/InputWrapper";
import Radio from "tpo/Radio";
import Stack from "tpo/Stack";
import { ViewerContext } from "tpo/Viewer";

import Template from "./shared/Template";

const GET_PROFILE_QUERY = gql`
  query GetProfileQuery($userId: ID!) {
    user(userId: $userId) {
      id
      email
      firstName
      lastName
      platformUserProfile {
        id
        height
        weight
        dateOfBirth
        gender
        goals {
          id
          text
        }
        dietPreferences {
          id
          name
        }
      }
    }
  }
`;

function Profile({
  firstName,
  lastName,
  email,
  weight,
  height,
  dateOfBirth,
  gender,
  dietPreferences,
  goals
}) {
  return (
    <>
      <Box px={20}>
        <PartnerDashboardBox
          mt={theme.spacing.section.pt}
          mb={theme.spacing.section.pb}
          pt={theme.spacing.figma.medium}
          pb={theme.spacing.figma.large}
        >
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Patient details
          </Box>
          <Stack gap={[20, 20, 40]}>
            <FloatingLabelInput label="First name" value={firstName} editable={false} />
            <FloatingLabelInput label="Last name" value={lastName} editable={false} />
            <FloatingLabelInput label="Email" value={email} editable={false} />
            <Stack gap={[20, 20, 40]}>
              <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                Physical details
              </Box>
              <FloatingLabelInput label="Height" value={height} editable={false} />
              <FloatingLabelInput label="Weight" value={weight} editable={false} />
            </Stack>
            <DateInput
              getDay={dateStr => (dateStr ? dateStr.split("-")[2] : "")}
              getMonth={dateStr => (dateStr ? dateStr.split("-")[1] : "")}
              getYear={dateStr => (dateStr ? dateStr.split("-")[0] : "")}
              label="Date of birth"
              labelProps={{
                fontFamily: "gilroyBold",
                fontSize: 18,
                mb: 20
              }}
              disabled
              value={dateOfBirth}
            />
            <InputWrapper
              labelProps={{
                fontFamily: "gilroyBold",
                fontSize: 18,
                mb: 20
              }}
              label="Gender"
              input={
                <Stack gap={20}>
                  <Radio value="Male" label="Male" checked={gender === "Male"} disabled />
                  <Radio value="Female" label="Female" checked={gender === "Female"} disabled />
                </Stack>
              }
            />
          </Stack>
        </PartnerDashboardBox>
      </Box>
      {(dietPreferences?.length || goals?.length) && (
        <PartnerDashboardBox borderRadius={0} mt="auto" maxWidth={null}>
          {!!goals?.length && (
            <Stack gap={[20, 20, 40]}>
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Goals
              </Box>
              <Group gap={10} flexWrap="wrap">
                {goals.map(g => (
                  <Badge bg="green" color="white" size="md" borderRadius={5} key={g.id} py={2}>
                    {g.text}
                  </Badge>
                ))}
              </Group>
            </Stack>
          )}
          {!!dietPreferences?.length && (
            <Stack gap={[20, 20, 40]}>
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Diet preferences
              </Box>
              <Group gap={10} flexWrap="wrap">
                {dietPreferences.map(dp => (
                  <Badge bg="green" color="white" size="md" borderRadius={5} key={dp.id} py={2}>
                    {dp.name}
                  </Badge>
                ))}
              </Group>
            </Stack>
          )}
        </PartnerDashboardBox>
      )}
    </>
  );
}

export default function ProfileTab() {
  const { userId } = useContext(ViewerContext);
  useDocTitle("Profile", false, "Regenerus");

  return (
    <Template
      selectedTab="profile"
      docTitle="Patient's profile"
      bodyProps={{
        as: Stack,
        gap: 0
      }}
    >
      <DataLoader
        query={GET_PROFILE_QUERY}
        variables={{
          userId
        }}
        render={({ user }) =>
          user ? (
            <Profile
              firstName={user.firstName}
              lastName={user.lastName}
              email={user.email}
              height={user.platformUserProfile.height}
              weight={user.platformUserProfile.weight}
              dateOfBirth={user.platformUserProfile.dateOfBirth}
              gender={user.platformUserProfile.gender}
              goals={user.platformUserProfile.goals}
              dietPreferences={user.platformUserProfile.dietPreferences}
            />
          ) : null
        }
      />
    </Template>
  );
}
