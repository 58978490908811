import { AccountOnHoldCheckoutMessage } from "components/AccountOnHold";
import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import Loading from "components/Loading";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function PayOrderNowFields({ canSubmit, loading }) {
  const { organisation } = useOrganisationContext();

  if (organisation?.accountOnHold) {
    return <AccountOnHoldCheckoutMessage />;
  }

  return (
    <PartnerDashboardBox>
      <Stack gap={[24, 24, 28]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Payment Details
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          <Box as="p" fontFamily="inherit" fontSize="inherit">
            You still need to{" "}
            <InternalTextLink href="/partners/dashboard/business">
              link your business bank account
            </InternalTextLink>{" "}
            to enable invoice payments.
          </Box>
          <Box as="p" fontFamily="inherit" fontSize="inherit">
            Please pay now to place this order.
          </Box>
        </Box>
      </Stack>
      <Center>
        <ButtonV2
          color="green"
          rightIcon={
            loading ? (
              <Loading
                minHeight={20}
                size={20}
                color="white"
                style={{
                  marginLeft: 10
                }}
              />
            ) : (
              <ChevronComponent />
            )
          }
          type="submit"
          disabled={!canSubmit || loading}
        >
          place order
        </ButtonV2>
      </Center>
    </PartnerDashboardBox>
  );
}
