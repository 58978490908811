import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { getEventUrl } from "core/urls";
import useWindowSize from "hooks/use-window-size";
import CpdIcon from "images/CpdIcon";
import Badge from "tpo/Badge";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import classes from "./EventCard.module.css";

const EventCard = forwardRef(
  ({ shortDescription, title, startTime, location, type, cpdPoints, slug }, ref) => {
    const history = useHistory();

    const { width } = useWindowSize();
    const isMobile = width < 768;

    if (isMobile) {
      return (
        <Stack
          borderRadius={20}
          bg="white"
          p={40}
          gap={20}
          onClick={() => {
            history.push(getEventUrl(slug));
          }}
        >
          <Stack gap={8}>
            <Box fontFamily="gilroyBold" fontSize={[24]}>
              {title}
            </Box>
            <Badge bg="purple" color="white" mr="auto">
              {type.replace("_", " ")}
            </Badge>
          </Stack>
          <Stack>
            <Box fontFamily="gilroyMedium" color="purple">
              {location}
            </Box>
            <Box fontFamily="gilroyBold" color="purple" whiteSpace="nowrap">
              {new Date(startTime).toLocaleDateString(undefined)}
            </Box>
            <Box fontFamily="gilroyBold" color="purple" whiteSpace="nowrap">
              {new Date(startTime).toLocaleTimeString(undefined, {
                timeZoneName: "short"
              })}
            </Box>
          </Stack>
          <Box className={classes.truncateText}>{shortDescription}</Box>
          <Group justifyContent="space-between">
            {!!cpdPoints && (
              <Group gap={10}>
                <CpdIcon />
                <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                  {cpdPoints > 1 ? `${cpdPoints}pts` : `${cpdPoints}pt`}
                </Box>
              </Group>
            )}
            <ButtonV2
              color="dark"
              ml="auto"
              mt="auto"
              size="xs"
              sx={{
                px: 20,
                fontSize: 12
              }}
            >
              register
            </ButtonV2>
          </Group>
        </Stack>
      );
    }

    return (
      <tr
        onClick={() => {
          history.push(getEventUrl(slug));
        }}
        className={classes.tr}
        ref={ref}
        style={{
          height: "100%"
        }}
      >
        <Box
          bg="white"
          as="td"
          pl={40}
          borderTopLeftRadius={20}
          borderBottomLeftRadius={20}
          py={40}
          height="100%"
        >
          <Stack height="100%">
            <Stack gap={8} mb={40}>
              <Box fontFamily="gilroyBold" fontSize={[24]}>
                {title}
              </Box>
              <Badge bg="purple" color="white" mr="auto">
                {type.replace("_", " ")}
              </Badge>
            </Stack>
            {!!cpdPoints && (
              <Group alignItems="center" gap={10} mt="auto">
                <CpdIcon />
                <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                  {cpdPoints > 1 ? `${cpdPoints}pts` : `${cpdPoints}pt`}
                </Box>
              </Group>
            )}
          </Stack>
        </Box>
        <Box bg="white" as="td" py={40} height="100%" px={20}>
          <Stack height="100%">
            <Box fontFamily="gilroyMedium" color="purple">
              {location}
            </Box>
            <Box fontFamily="gilroyBold" color="purple" whiteSpace="nowrap">
              {new Date(startTime).toLocaleDateString(undefined)}
            </Box>
            <Box fontFamily="gilroyBold" color="purple" whiteSpace="nowrap">
              {new Date(startTime).toLocaleTimeString(undefined, {
                timeZoneName: "short"
              })}
            </Box>
          </Stack>
        </Box>
        <Box
          py={40}
          bg="white"
          as="td"
          pr={40}
          borderTopRightRadius={20}
          borderBottomRightRadius={20}
          height="100%"
        >
          <Stack height="100%">
            <Box className={classes.truncateText}>{shortDescription}</Box>
            <ButtonV2
              color="dark"
              ml="auto"
              mt="auto"
              size="xs"
              sx={{
                px: 20,
                fontSize: 12
              }}
            >
              register
            </ButtonV2>
          </Stack>
        </Box>
      </tr>
    );
  }
);

export default EventCard;
