import { gql } from "@apollo/client";
import { PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS } from "graphql/shop";

export const SUPPORTING_DOCUMENT_FIELDS = gql`
  fragment SupportingDocumentFields on SupportingDocumentType {
    id
    title
    displayName
    url
    contentType
    description
    filename
    type
    testProductNames
  }
`;

export const VIDEO_FIELDS = gql`
  fragment VideoFields on VideoType {
    id
    url
    thumbnailUrl
    userStarted
    userEnded
  }
`;

export const VIDEO_GUIDE_FIELDS = gql`
  fragment VideoGuideFields on VideoGuideType {
    id
    video {
      ...VideoFields
    }
    title
    shortDescription
  }
  ${VIDEO_FIELDS}
`;

export const COURSE_VIDEO_FIELDS = gql`
  fragment CourseVideoFields on CourseVideoType {
    id
    title
    video {
      ...VideoFields
    }
  }
  ${VIDEO_FIELDS}
`;

export const COURSE_MODULE_FIELDS = gql`
  fragment CourseModuleFields on CourseModuleType {
    id
    title
    videos {
      ...CourseVideoFields
    }
  }
  ${COURSE_VIDEO_FIELDS}
`;

export const COURSE_FIELDS = gql`
  fragment CourseFields on CourseType {
    id
    title
    subtitle
    slug
    description
    shortDescription
    hasExclusiveAccess
    exclusiveAccessStartTime
    exclusiveAccessEndTime
    modules {
      ...CourseModuleFields
    }
    documents {
      ...SupportingDocumentFields
    }
    cpdPoints
    imageUrl
    backgroundColor
    testProducts(paymentIsoCode: $paymentIsoCode, organisation: $organisation) {
      ...PractitionerTestProductConnectionFields
      laboratory {
        id
        name
        logoUrl
      }
    }
    offerText
    offerCaveat
    discountCode {
      id
      code
    }
    introductionVideo {
      ...VideoFields
    }
  }
  ${VIDEO_FIELDS}
  ${COURSE_MODULE_FIELDS}
  ${PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS}
  ${SUPPORTING_DOCUMENT_FIELDS}
`;

export const WEBINAR_FIELDS = gql`
  fragment WebinarFields on WebinarType {
    id
    title
    slug
    shortDescription
    description
    startTime
    endTime
    registerNowUrl
    cpdPoints
    video {
      ...VideoFields
    }
    testProducts(paymentIsoCode: $paymentIsoCode, organisation: $organisation) {
      ...PractitionerTestProductConnectionFields
      laboratory {
        id
        name
        logoUrl
      }
    }
    host {
      id
      firstName
      lastName
      fullName
      avatarUrl
      title
      occupation
    }
    documents {
      ...SupportingDocumentFields
    }
  }
  ${PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS}
  ${SUPPORTING_DOCUMENT_FIELDS}
  ${VIDEO_FIELDS}
`;

export const EVENT_FIELDS = gql`
  fragment EventFields on EventType {
    id
    title
    startTime
    endTime
    description
    shortDescription
    location
    type
    cpdPoints
    slug
    registerNowUrl
  }
`;

export const EVENT_QUERY = gql`
  query EventQuery($id: ID, $slug: String) {
    event(id: $id, slug: $slug) {
      ...EventFields
    }
  }
  ${EVENT_FIELDS}
`;

export const COURSE_QUERY = gql`
  query CourseQuery($id: ID, $slug: String, $paymentIsoCode: String, $organisation: ID) {
    course(id: $id, slug: $slug) {
      ...CourseFields
    }
  }
  ${COURSE_FIELDS}
`;

export const WEBINAR_QUERY = gql`
  query WebinarQuery($id: ID, $slug: String, $paymentIsoCode: String, $organisation: ID) {
    webinar(id: $id, slug: $slug) {
      ...WebinarFields
    }
  }
  ${WEBINAR_FIELDS}
`;

export const COURSE_CONNECTIONS_QUERY = gql`
  query CourseConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $paymentIsoCode: String
    $organisation: ID
  ) {
    courseConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
    ) {
      edges {
        node {
          ...CourseFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${COURSE_FIELDS}
`;

export const HOMEPAGE_QUERY = gql`
  query HomepageQuery($paymentIsoCode: String, $organisation: ID) {
    learningHubHomepage {
      webinars {
        ...WebinarFields
      }
      courses {
        ...CourseFields
      }
      events {
        ...EventFields
      }
    }
  }
  ${COURSE_FIELDS}
  ${EVENT_FIELDS}
  ${WEBINAR_FIELDS}
`;

export const WEBINAR_CONNECTIONS_QUERY = gql`
  query WebinarConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $upcoming: Boolean
    $exclude: [ID!]
    $paymentIsoCode: String
    $organisation: ID
  ) {
    webinarConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
      upcoming: $upcoming
      exclude: $exclude
    ) {
      edges {
        node {
          ...WebinarFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${WEBINAR_FIELDS}
`;

export const SUPPORT_DOCUMENT_CONNECTIONS_QUERY = gql`
  query SupportDocumentConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
  ) {
    documentConnections(first: $first, after: $after, orderBy: $orderBy, search: $search) {
      edges {
        node {
          ...SupportingDocumentFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SUPPORTING_DOCUMENT_FIELDS}
`;

export const EVENT_CONNECTIONS_QUERY = gql`
  query EventConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $types: [String!]
  ) {
    eventConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      types: $types
    ) {
      edges {
        node {
          ...EventFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${EVENT_FIELDS}
`;

export const FAQ_FIELDS = gql`
  fragment FaqTypeFields on FaqType {
    id
    question
    answer
  }
`;

export const FAQ_CONNECTIONS_QUERY = gql`
  query FaqConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $topics: [ID!]
  ) {
    faqConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      topics: $topics
    ) {
      edges {
        node {
          ...FaqTypeFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${FAQ_FIELDS}
`;

export const VIDEO_GUIDE_CONNECTIONS_QUERY = gql`
  query VideoGuideConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $topics: [ID!]
  ) {
    videoGuideConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      topics: $topics
    ) {
      edges {
        node {
          ...VideoGuideFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${VIDEO_GUIDE_FIELDS}
`;

const TOPIC_FIELDS = gql`
  fragment TopicFields on TopicType {
    id
    name
  }
`;

export const TOPIC_QUERY = gql`
  query Topics {
    topics {
      ...TopicFields
    }
  }
  ${TOPIC_FIELDS}
`;
