export const PAYMENT_OBJECT_STATUS_CREATED = "CREATED";
export const PAYMENT_OBJECT_STATUS_PAID = "PAID";
export const PAYMENT_INITIATED = "Payment initiated";

export const BASKET_PAYEE_PATIENT = "patient";

// Tip types
export const DO_TIP = "DO_TIP";
export const DONT_TIP = "DONT_TIP";

// Sectors
export const GENETICS = "Genetics";
export const HEALTH = "Health";
export const NUTRITION = "Nutrition";
export const STRESS = "Stress";
export const FITNESS = "Fitness";
export const LIFESTYLE = "Lifestyle";
export const INITIAL = "Initial";
export const FOOD = "Food";

export const SUPPLEMENTS = "Supplements";

// Product statuses
export const AVAILABLE = "Available";
export const COMING_SOON = "Coming soon";
export const OUT_OF_STOCK = "Out of stock";

// Product types
export const TEST = "Test";
export const SUPPLEMENT = "Supplement";

// Recommendations
export const THINGS_TO_DO = "Things to do";
export const THINGS_TO_AVOID = "Things to avoid";
export const THINGS_TO_TAKE = "Things to take";
export const PEOPLE_TO_SEE = "People to see";

// Filters
export const ALL = "All";
export const RECOMMENDED = "Recommended";
export const OUTSIDE_RANGE = "Outside range";

// Partner tiers
export const AFFILIATE = "Affiliate";
export const PRACTITIONER = "Practitioner";
export const EXPERT = "Expert";

// Interpretations
export const VERY_LOW = "very_low";
export const LOW = "low";
export const NORMAL = "normal";
export const HIGH = "high";
export const VERY_HIGH = "very_high";
export const ABSENT = "absent";
export const DETECTED = "detected";
export const INSUFFICIENT = "insufficient";
export const SUFFICIENT = "sufficient";
export const IMBALANCED = "imbalanced";
export const BALANCED = "balanced";

export const EXCLUDE_RESULTS = ["mushroom-sensitivity-risk"];

export const MODAL_OVERLAY_Z_INDEX = 1000;
export const FLAG_Z_INDEX = 1;
export const MAIN_MENU_Z_INDEX = MODAL_OVERLAY_Z_INDEX + FLAG_Z_INDEX + 1;
export const NAV_Z_INDEX = MAIN_MENU_Z_INDEX + 1;
export const MODAL_Z_INDEX = NAV_Z_INDEX + 1;

export const EXPIRED_MARKERS = "Expired markers";

export const REVIEW_STATUS_FOR_REVIEW = "For review";
export const REVIEW_STATUS_FOR_PRACTITIONER_REVIEW = "For practitioner review";
export const REVIEW_STATUS_APPROVED = "Approved";
export const REVIEW_STATUS_RETEST = "Needs retest";
export const REVIEW_STATUS_CONSULT_GP = "Consult GP";
export const REVIEW_STATUS_PARTIAL = "Partial results";

export const EXPERT_AVAILABILITY_ONLINE = "Online";
export const EXPERT_AVAILABILITY_IN_PERSON = "In person";

export const BASKET_INVALID_RESTRICTED_SHIPPING = "RESTRICTED_SHIPPING";
export const BASKET_INVALID_RESTRICTED_ORGANISATION = "RESTRICTED_ORGANISATION";

export const ORDER_RESEND_STATUS = "Resend";
export const ORDER_RETEST_STATUS = "Retest";

export const ISO_CODE_TO_COUNTRY_NAME = [
  {
    code: "AU",
    name: "Australia"
  },
  {
    code: "AT",
    name: "Austria"
  },
  {
    code: "BE",
    name: "Belgium"
  },
  {
    code: "BR",
    name: "Brazil"
  },
  {
    code: "BG",
    name: "Bulgaria"
  },
  {
    code: "CA",
    name: "Canada"
  },
  {
    code: "HR",
    name: "Croatia"
  },
  {
    code: "CY",
    name: "Cyprus"
  },
  {
    code: "CZ",
    name: "Czech Republic"
  },
  {
    code: "DK",
    name: "Denmark"
  },
  {
    code: "EE",
    name: "Estonia"
  },
  {
    code: "FI",
    name: "Finland"
  },
  {
    code: "FR",
    name: "France"
  },
  {
    code: "DE",
    name: "Germany"
  },
  {
    code: "GI",
    name: "Gibraltar"
  },
  {
    code: "GR",
    name: "Greece"
  },
  {
    code: "HK",
    name: "Hong Kong"
  },
  {
    code: "HU",
    name: "Hungary"
  },
  {
    code: "IN",
    name: "India"
  },
  {
    code: "ID",
    name: "Indonesia"
  },
  {
    code: "IE",
    name: "Ireland"
  },
  {
    code: "IT",
    name: "Italy"
  },
  {
    code: "JP",
    name: "Japan"
  },
  {
    code: "LV",
    name: "Latvia"
  },
  {
    code: "LI",
    name: "Liechtenstein"
  },
  {
    code: "LT",
    name: "Lithuania"
  },
  {
    code: "LU",
    name: "Luxembourg"
  },
  {
    code: "MY",
    name: "Malaysia"
  },
  {
    code: "MT",
    name: "Malta"
  },
  {
    code: "MX",
    name: "Mexico"
  },
  {
    code: "NL",
    name: "Netherlands"
  },
  {
    code: "NZ",
    name: "New Zealand"
  },
  {
    code: "NO",
    name: "Norway"
  },
  {
    code: "PL",
    name: "Poland"
  },
  {
    code: "PT",
    name: "Portugal"
  },
  {
    code: "RO",
    name: "Romania"
  },
  {
    code: "SG",
    name: "Singapore"
  },
  {
    code: "SK",
    name: "Slovakia"
  },
  {
    code: "SI",
    name: "Slovenia"
  },
  {
    code: "ES",
    name: "Spain"
  },
  {
    code: "SE",
    name: "Sweden"
  },
  {
    code: "CH",
    name: "Switzerland"
  },
  {
    code: "TH",
    name: "Thailand"
  },
  {
    code: "AE",
    name: "United Arab Emirates"
  },
  {
    code: "GB",
    name: "United Kingdom"
  },
  {
    code: "US",
    name: "United States"
  }
].reduce((acc, obj) => ({ ...acc, [obj.code]: obj.name }), {});
