// We are no longer accepting new affiliates
// So redirect to the practitioner sign up page
import Box from "components/Box";
import Page from "components/Page";
import { theme } from "core/theme";
import Jumbotron from "tpo/Jumbotron";
import Spacer from "tpo/Spacer";

export default function ApplicationSubmitted() {
  return (
    <Page bg="haze">
      <Jumbotron bg="partners" color="dark" />
      <Box pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={[20, 20, "5.5vw"]}>
        <Box maxWidth={760} mx="auto">
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Application submitted
          </Box>
          <Spacer py={2} />
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Thanks for applying to become a Regenerus Practitioner. We will let you know as soon as
            you have been approved. If you have any questions please contact info@regeneruslabs.com.
          </Box>
        </Box>
      </Box>
    </Page>
  );
}
