import { useEffect, useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { getAPIBase } from "core/config";
import {
  ORDER_RESEND_STATUS,
  ORDER_RETEST_STATUS,
  PAYMENT_OBJECT_STATUS_CREATED,
  PAYMENT_OBJECT_STATUS_PAID
} from "core/constants";
import Badge from "tpo/Badge";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import OrderStatusBadges from "../ui/OrderStatusBadges";

function StripePaymentLink({ url, text, newTab = false }) {
  return (
    <ExternalTextLink
      onClick={e => {
        e.clickWithinOrganisationOrderSummaryComponent = true;
      }}
      href={url}
    >
      {text}
    </ExternalTextLink>
  );
}

function parseStripeDetails(payee, paymentCheckoutSession, paymentInvoice, orderId) {
  if (paymentInvoice?.url) {
    // Paid or not, if there's an invoice URL, show it
    return {
      label: "Payment:",
      value: <StripePaymentLink url={paymentInvoice.url} text="View Invoice" newTab={true} />
    };
  }

  const isPatient = payee === "patient";
  const isPaid = paymentCheckoutSession?.paymentStatus === PAYMENT_OBJECT_STATUS_PAID;

  // Handle paid checkout sessions
  if (isPaid) {
    if (paymentCheckoutSession.invoiceUrl) {
      const text = isPatient ? "View Details" : "View Invoice";
      return {
        label: "Payment:",
        value: (
          <StripePaymentLink url={paymentCheckoutSession.invoiceUrl} text={text} newTab={true} />
        )
      };
    }
    return null;
  }

  // Handle unpaid checkout sessions
  if (paymentCheckoutSession) {
    const text = isPatient ? "Payment Link" : "Pay now";
    return {
      label: "Payment:",
      value: <StripePaymentLink url={`${getAPIBase()}/payments/checkout/${orderId}`} text={text} />
    };
  }

  return null;
}

export default function OrganisationOrderSummary({ order }) {
  const stripeDetails = parseStripeDetails(
    order.sourceBasket.payee,
    order.sourceBasket.paymentCheckoutSession,
    order.sourceBasket.paymentInvoice,
    order.id
  );

  let additionalStatus = null;

  if (order.suppliedFromPracticeStock) {
    additionalStatus = "From Stock";
  } else if (order.isStockOrder) {
    additionalStatus = "Stock Order";
  } else if (order.status === ORDER_RESEND_STATUS || order.status === ORDER_RETEST_STATUS) {
    additionalStatus = order.status;
  }

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      py={4}
      gap={20}
    >
      <Stack gap={10}>
        {additionalStatus && (
          <Box alignItems="flex-start" fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            <Badge bg="black" color="white" ml="auto" py="8px" px="16px" fontSize={10}>
              {additionalStatus}
            </Badge>
          </Box>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order No:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDetails:orderNo">
            {order.id}
          </Box>
        </Group>
        {stripeDetails && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]} data-testid="stripeDetails:label">
              {stripeDetails.label}
            </Box>
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="stripeDetails:value"
            >
              {stripeDetails.value}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order Date:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDate">
            {new Date(order.checkoutDate).toLocaleDateString()}
          </Box>
        </Group>
        {order.sourceBasket &&
        order.sourceBasket.paymentInvoice &&
        order.sourceBasket.paymentInvoice.dateDue &&
        order.sourceBasket.paymentInvoice.paymentStatus === PAYMENT_OBJECT_STATUS_CREATED ? (
          <Group gap={10} alignItems="center" flexWrap="wrap">
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Payment due:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="paymentDue">
              {new Date(order.sourceBasket.paymentInvoice.dateDue).toLocaleDateString()}
            </Box>
            {new Date(order.sourceBasket.paymentInvoice.dateDue) > new Date() &&
            order.sourceBasket.paymentInvoice.url ? (
              <ButtonV2
                as="a"
                color="green"
                href={order.sourceBasket.paymentInvoice.url}
                sx={{
                  lineHeight: 1,
                  cursor: "pointer",
                  px: 20,
                  py: 2,
                  fontSize: 12
                }}
                data-testid="payNowButton"
                onClick={e => {
                  e.clickWithinOrganisationOrderSummaryComponent = true;
                }}
                size="xs"
              >
                pay now
              </ButtonV2>
            ) : null}
          </Group>
        ) : null}

        {!!order.estimatedResultsAvailableDate && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Results expected:
            </Box>
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="estimatedResultsAvailableDate"
            >
              {new Date(order.estimatedResultsAvailableDate).toLocaleDateString()}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Name:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderName">
            {order.user.firstName} {order.user.lastName}
          </Box>
        </Group>
      </Stack>
      <OrderStatusBadges order={order} />
    </Box>
  );
}
