function Filled({ fill, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.712 1.8479C10.4745 2.09399 10.2527 2.34404 9.96831 2.64802C9.66829 2.32441 9.426 2.0323 9.15418 1.7722C8.0631 0.729121 6.82425 0.206595 5.46053 0.206595C4.9672 0.206595 4.45721 0.275044 3.93221 0.411944C2.8065 0.709658 1.81109 1.37057 1.09938 2.29241C0.388495 3.21426 0.00171332 4.34557 6.64703e-05 5.50971C-0.0116467 7.12776 0.628316 8.4801 1.75023 9.60976C3.71581 11.5918 5.6964 13.5601 7.66841 15.5367C8.42844 16.2961 9.18469 17.0625 9.96436 17.8485C10.0645 17.7586 10.1262 17.704 10.1849 17.6461C12.862 14.9647 15.5388 12.2841 18.2163 9.60366C18.4147 9.40986 18.5976 9.20121 18.7647 8.98019C20.0482 7.24421 20.4022 5.34807 19.4906 3.36204C18.7544 1.73624 17.2606 0.581501 15.5028 0.278343C15.1977 0.225727 14.8917 0.199997 14.5876 0.199997C13.1388 0.199997 11.7356 0.785695 10.712 1.8479Z"
        fill="inherit"
      />
    </svg>
  );
}

function Outline({ fill, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41238 1.2C6.8612 1.2 8.26433 1.78409 9.28793 2.84338C9.52544 3.08879 9.74729 3.33815 10.0316 3.6413C10.3317 3.31858 10.574 3.02727 10.8458 2.76788C11.9368 1.72767 13.1757 1.20658 14.5394 1.20658C15.0327 1.20658 15.5427 1.27484 16.0677 1.41136C17.1934 1.70826 18.1888 2.36735 18.9005 3.28667C19.6114 4.20598 19.9982 5.33419 19.9998 6.49513C20.0116 8.10874 19.3716 9.45736 18.2497 10.5839C16.2841 12.5605 14.3035 14.5234 12.3315 16.4946C11.5715 17.2519 10.8153 18.0162 10.0356 18.8C9.93565 18.7104 9.8738 18.6559 9.81508 18.5982C7.13797 15.9241 4.46118 13.2509 1.78374 10.5778C1.58532 10.3846 1.4024 10.1765 1.23532 9.95608C-0.0482254 8.22486 -0.402184 6.33393 0.50943 4.35336C1.24555 2.73202 2.7394 1.58045 4.49714 1.27813C4.80228 1.22566 5.10824 1.2 5.41238 1.2ZM5.41222 2.51588C5.18163 2.51588 4.94907 2.53579 4.72146 2.57494C3.39288 2.80341 2.26767 3.67107 1.7087 4.90241C1.04482 6.34462 1.23549 7.73979 2.29192 9.16786C2.41974 9.3358 2.55895 9.49338 2.70558 9.63632L2.71152 9.64207L2.71729 9.64783C5.15277 12.0794 7.58759 14.511 10.0229 16.9433C10.489 16.4742 10.9386 16.0223 11.3975 15.5651C12.1328 14.83 12.8696 14.096 13.6062 13.3621C14.8233 12.1493 16.0819 10.8951 17.3135 9.65688C18.2418 8.72457 18.6889 7.69357 18.6803 6.49694C18.6792 5.63108 18.3863 4.77642 17.856 4.09084C17.3249 3.40477 16.57 2.90489 15.7344 2.68465C15.3207 2.57691 14.9188 2.52246 14.5394 2.52246C13.5122 2.52246 12.6022 2.91378 11.758 3.71861C11.6146 3.85546 11.4697 4.01665 11.3018 4.20335C11.2055 4.31043 11.1059 4.42129 10.999 4.5361L10.0359 5.57171L9.06824 4.54038C8.95047 4.41488 8.84326 4.29842 8.74117 4.18756C8.59503 4.02899 8.46885 3.89197 8.33756 3.75644C7.57604 2.96806 6.50972 2.51588 5.41222 2.51588Z"
        fill="inherit"
      />
    </svg>
  );
}

export default function LifeStyleIcon({ size, color, outline = true, filled = false }) {
  return (
    <>
      {outline && <Outline width={size} height={size} fill={color} />}
      {filled && !outline && <Filled width={size} height={size} fill={color} />}
    </>
  );
}
