import React, { useEffect, useState } from "react";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { RetryLink } from "apollo-link-retry";

import { getAPIBase } from "../core/config";
import { LOGIN_URL } from "../core/urls";
import { useAuthContext } from "./AuthProvider";
import Loading from "./Loading";

function getClient(csrfToken) {
  return new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      new RetryLink({
        delay: {
          initial: 500,
          max: Infinity,
          jitter: true
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => !!error
        }
      }),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            if (message.includes("You do not have permission to perform this action")) {
              // If for some reason the session is no longer valid, hard refresh to the login page
              window.location.href = `${LOGIN_URL}?next=${window.location.pathname}`;
            }
          });
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
        if (networkError && networkError.statusCode === 403) {
          // If for some reason we have a CSRF issue, hard refresh to force a new value
          window.location.reload();
        }
      }),
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            "X-CSRFToken": csrfToken
          }
        };
      }),
      new HttpLink({
        uri: `${getAPIBase()}/api/graphql/`,
        credentials: "include"
      })
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            examples: {
              keyArgs: ["userId", "userSubsectorId", "verb", "category"]
            },
            expertTestProducts: relayStylePagination(["search", "orderBy"]),
            expertConnections: relayStylePagination([
              "search",
              "orderBy",
              "consultationTypes",
              "supportedTests",
              "practitionerTypes",
              "specialisms"
            ]),
            practitionerClinicLocations: {
              keyArgs: ["paymentIsoCode", "postCode", "compositeId"]
            },
            userDatapointConnections: relayStylePagination([
              "userSubsectorId",
              "userTestId",
              "excludeGenesRelated",
              "orderBy",
              "search",
              "userId"
            ]),
            userResultConnections: relayStylePagination([
              "subsectorRelatedOnly",
              "orderBy",
              "search",
              "userId"
            ]),
            supplementConnections: relayStylePagination([
              "orderBy",
              "search",
              "productAudience",
              "brandNames",
              "doseTypes",
              "dietryRestrictions"
            ]),
            consumerTestProductConnections: relayStylePagination([
              "orderBy",
              "search",
              "productAudience",
              "gender",
              "testingServices",
              "areasOfInterest",
              "sampleTypes",
              "isoCode",
              "paymentIsoCode"
            ]),
            courseConnections: relayStylePagination([
              "orderBy",
              "search",
              "testingServices",
              "areasOfInterest",
              "sampleTypes"
            ]),
            practitionerTestProductConnections: relayStylePagination([
              "orderBy",
              "search",
              "productAudience",
              "gender",
              "testingServices",
              "areasOfInterest",
              "sampleTypes",
              "isoCode",
              "paymentIsoCode",
              "organisation",
              "filterByFavourites"
            ]),
            webinarConnections: relayStylePagination([
              "orderBy",
              "search",
              "testingServices",
              "areasOfInterest",
              "sampleTypes",
              "upcoming",
              "exclude"
            ]),
            documentConnections: relayStylePagination(["orderBy", "search"]),
            eventConnections: relayStylePagination(["orderBy", "search", "types"]),
            faqConnections: relayStylePagination(["orderBy", "search", "topics"]),
            videoGuideConnections: relayStylePagination(["orderBy", "search", "topics"])
          }
        },
        ClinicLocationType: {
          keyFields: ["id", "paymentIsoCode", "organisation"]
        },
        TestProductType: {
          keyFields: ["id", "paymentIsoCode", "organisation"]
        },
        TestProductOptionType: {
          keyFields: ["id", "paymentIsoCode", "organisation"]
        },
        UserTestType: {
          fields: {
            userResultConnections: relayStylePagination(["orderBy", "displayGroup"])
          }
        },
        UserSubsectorType: {
          fields: {
            userResultConnections: relayStylePagination(["orderBy", "search"])
          }
        },
        CourseType: {
          fields: {
            testProducts: {
              keyFields: ["paymentIsoCode", "organisation"]
            }
          }
        },
        WebinarType: {
          fields: {
            testProducts: {
              keyFields: ["paymentIsoCode", "organisation"]
            }
          }
        }
      }
    })
  });
}

export default function ApolloManager({ children }) {
  const { csrfToken } = useAuthContext();

  const [apolloClient, setApolloClient] = useState(null);

  useEffect(() => {
    if (csrfToken !== undefined) {
      setApolloClient(getClient(csrfToken));
    }
  }, [csrfToken]);

  if (apolloClient === null) {
    return <Loading />;
  } else {
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
  }
}
