import { useTheme } from "styled-components";
import Center from "tpo/Center";
import NothingToShow from "tpo/NothingToShow";

export default function NoTestsAndOrders() {
  const theme = useTheme();

  return (
    <Center pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={20}>
      <NothingToShow
        header="No purchased tests"
        bodyCopy="Your patient currently has no purchased tests. Details will be available here once an order has been placed."
      />
    </Center>
  );
}
