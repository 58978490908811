import Box from "components/Box";
import RichText from "components/RichText";
import { theme } from "core/theme";
import LifeStyleIcon from "images/LifeStyleIcon";
import Badge from "tpo/Badge";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { ActionIcon } from "v2/Buttons";

import AddToBasketSection from "./AddToBasketSection";

export default function MainDetails({
  onTogglingFavourite,
  favourite,
  status,
  name,
  description,
  categories,
  numOfBiomarkersTested,
  processingTime,
  priceElement,
  testType,
  practitionerSupportRequired,
  onAddToBasket,
  setQty,
  options,
  centrifuge
}) {
  return (
    <>
      <Box>
        {practitionerSupportRequired && (
          <>
            <Badge bg="partners" color="dark" mr="auto">
              practitioner support required
            </Badge>
            <Spacer py={[20, 20, 2]} />
          </>
        )}
        <Group alignItems="center" gap={10}>
          {!!onTogglingFavourite && (
            <ActionIcon
              size={["sm", "sm", "md"]}
              bg="haze"
              variant="circle"
              onClick={onTogglingFavourite}
            >
              <LifeStyleIcon
                size={20}
                outline={!favourite}
                filled={favourite}
                color={favourite ? theme.colors.genetics : "black"}
              />
            </ActionIcon>
          )}
          <Box
            fontFamily="gilroyBold"
            fontSize={[18, 18, 28]}
            lineHeight="130%"
            data-testid="MainDetails:Name"
          >
            {name}
          </Box>
        </Group>
        <Spacer py={[7.5, 7.5, 15]} />
        <RichText fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {description}
        </RichText>
        <Spacer py={[2, 2, 20]} />
        <Group flexWrap="wrap" gap={2}>
          {categories.map(c => (
            <Badge bg="lifestyle" color="dark" size="xs" key={c}>
              {c}
            </Badge>
          ))}
        </Group>
        <Spacer py={[2, 2, 20]} />
        <Stack>
          <Group alignItems="center" gap={10} flexWrap="wrap">
            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
              Sample type:
            </Box>
            <Badge bg="purple" color="white" size="xs">
              {testType}
            </Badge>
            {centrifuge && (
              <Badge bg="red" color="white" size="xs">
                centrifuge
              </Badge>
            )}
          </Group>
          <Group alignItems="center" gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px">
              Biomarkers tested:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16}>
              {numOfBiomarkersTested}
            </Box>
          </Group>
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16} lineHeight="16px" whiteSpace="nowrap">
              Lab Processing time:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16}>
              {processingTime || "N/A"}
            </Box>
          </Group>
        </Stack>
        {options ? (
          options
        ) : (
          <AddToBasketSection
            setQty={setQty}
            onAddToBasket={onAddToBasket}
            priceElement={priceElement}
            practitionerSupportRequired={practitionerSupportRequired}
            status={status}
          />
        )}
      </Box>
    </>
  );
}
