import { useContext } from "react";

import { Redirect, useParams } from "react-router-dom";

import DataLoader from "components/DataLoader";
import { ERROR_404_URL } from "core/urls";
import { PATIENT_TESTS } from "graphql/organisations/queries/patients";
import useUserProfile from "hooks/useUserProfile";
import { ViewerContext } from "tpo/Viewer";

import { BlurredOverlayProvider } from "../BlurredOverlay";
import Template from "../shared/Template";
import Tests from "./Tests";

function TestsTab() {
  const { id } = useParams();
  const { userId } = useContext(ViewerContext);
  const { partnerUserProfile } = useUserProfile();

  return (
    <BlurredOverlayProvider show={!partnerUserProfile?.wellnessScoresUnlocked}>
      <DataLoader
        query={PATIENT_TESTS}
        variables={{
          userId,
          userTestId: id,
          allTests: true,
          includeUnapproved: true
        }}
        render={({ userTests, userTest, user, ordersInProgress }) =>
          userTest ? (
            <ViewerContext.Provider
              value={{
                userId,
                practitionerOrganisationIds: user.partnerUserProfile.organisationSet.map(
                  org => org.id
                )
              }}
            >
              <Tests
                ordersInProgress={ordersInProgress}
                userTests={userTests}
                userTest={userTest}
              />
            </ViewerContext.Provider>
          ) : (
            <Redirect to={ERROR_404_URL} />
          )
        }
      />
    </BlurredOverlayProvider>
  );
}

export default function Test() {
  return (
    <Template selectedTab="tests" docTitle="Patient's Tests">
      <TestsTab />
    </Template>
  );
}
