import { Redirect } from "react-router-dom";

import { AFFILIATE, PRACTITIONER } from "core/constants";
import {
  PARTNERS_AFFILIATE_URL,
  PARTNERS_EXTERNAL_URL,
  PARTNERS_PRACTITIONER_URL
} from "core/urls";

import { useAuthContext } from "./AuthProvider";

function PartnersMarketingRedirect({ children }) {
  const { user } = useAuthContext();

  if (user) {
    if (user.partnerUserProfile) {
      if (user.partnerUserProfile.tier === AFFILIATE) {
        return <Redirect to={PARTNERS_AFFILIATE_URL} />;
      } else if (user.partnerUserProfile.tier === PRACTITIONER) {
        return <Redirect to={PARTNERS_PRACTITIONER_URL} />;
      } else {
        window.location.href = PARTNERS_EXTERNAL_URL;
        return null;
      }
    } else {
      window.location.href = PARTNERS_EXTERNAL_URL;
      return null;
    }
  } else {
    window.location.href = PARTNERS_EXTERNAL_URL;
    return null;
  }
}

export default PartnersMarketingRedirect;
