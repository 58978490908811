import { Redirect, useParams, useHistory } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import CustomerPage from "components/CustomerPage";
import SliderTabs, { SliderTab } from "components/SliderTabs";
import { ERROR_404_URL, getClientManagementTabUrl } from "core/urls";
import useDocTitle from "hooks/use-doc-title";
import { ExpiredMarkersToggleSwitch } from "tpo/ExpiredMarkersToggleSwitch";

const ALL_TABS = [
  {
    label: "profile",
    slug: "profile"
  },
  {
    label: "dashboard",
    slug: "dashboard"
  },
  {
    label: "tests",
    slug: "tests"
  },
  {
    label: "wellness scores",
    slug: "wellness"
  },
  {
    label: "self assessment",
    slug: "self_assessment"
  },
  {
    label: "suggestions",
    slug: "suggestions"
  },
  {
    label: "food list",
    slug: "food_list"
  }
];

const PATIENT_DETAILS = gql`
  query PatientDetails($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
    }
  }
`;

export default function Template({ children, selectedTab, bodyProps, docTitle }) {
  useDocTitle(docTitle, docTitle === undefined, "Regenerus");

  const { patientId } = useParams();
  const history = useHistory();

  const { data } = useQuery(PATIENT_DETAILS, {
    variables: {
      userId: patientId
    }
  });

  const user = data?.user;

  return (
    <CustomerPage
      bg="haze"
      jumbotronProps={{
        bg: "partners",
        color: "dark",
        title: user ? `${user.firstName} ${user.lastName}` : null,
        top: <ExpiredMarkersToggleSwitch />
      }}
      bodyProps={bodyProps}
    >
      {data ? (
        user ? (
          <>
            <SliderTabs
              bg="white"
              tabs={ALL_TABS}
              selectedTab={selectedTab}
              withBorder
              renderTab={({ tab, ...props }) => (
                <SliderTab
                  {...props}
                  tab={tab}
                  onClick={() => {
                    history.push(getClientManagementTabUrl(patientId, tab.slug));
                  }}
                />
              )}
            />
            {children}
          </>
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      ) : null}
    </CustomerPage>
  );
}
